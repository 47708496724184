<template>
  <div class="full-page-centered-wrapper">
    <div class="login-container">
      <h2 class="logo">
        <img
          class="logo"
          alt="Tuck Trucks Logo"
          :src="
            this.getCdnFile('/images/logos/tuck-trucks/tt-logo-black.png')
          " />
      </h2>
      <form @submit.prevent="submit">
        <div class="input-wrapper gap_1">
          <label for="username">Email Address</label>
          <input
            v-model="loginForm.username"
            id="username"
            name="username"
            type="email"
            required />
        </div>
        <div class="input-wrapper gap_1">
          <label for="password">Password</label>
          <div class="password-field full-width">
            <input
              v-model="loginForm.password"
              id="password"
              name="password"
              :type="!showPassword ? 'password' : 'text'"
              required />
            <button
              :onclick="togglePasswordVisibilty"
              type="button"
              class="icon-only small inline switch-visibility">
              <span
                class="material-icons material-icons-round"
                v-if="!showPassword">
                <Icon :path="mdiEyeOff" :size="18" />
              </span>
              <span
                class="material-icons material-icons-round"
                v-if="showPassword">
                <Icon :path="mdiEye" :size="18" />
              </span>
            </button>
          </div>
        </div>

        <button type="submit" class="full" :disabled="loading">
          <LoadingSpinner v-if="loading" />
          <span class="sign-in" v-else>Sign in</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import { useToast } from "vue-toastification";
  import { mapActions, mapGetters } from "vuex";
  import { ApiLogon } from "@tucktrucks/platform-base-private";
  import { mdiEyeOff, mdiEye } from "@mdi/js";

  export default {
    data() {
      return {
        loginForm: {
          username: "",
          password: "",
        },
        label: "Sign in",
        loading: false,
        showPassword: false,
        mdiEyeOff,
        mdiEye,
      };
    },

    computed: {
      ...mapGetters({}),
    },

    methods: {
      ...mapActions({
        login: "tokens/login",
      }),

      togglePasswordVisibilty() {
        this.showPassword = !this.showPassword;
      },

      clearTokens() {
        store.dispatch("tokens/clear");
        store.dispatch("selectedOperator/clear");
        store.dispatch("availableOperators/clear");
      },

      submit() {
        this.loading = true;
        this.label = "";
        this.clearTokens();

        // Create a login model with username & password
        const login = new ApiLogon({
          username: this.loginForm.username,
          password: this.loginForm.password,
        });

        // Fire off the tokens login endpoint with this model.
        store.state.apiPrivate.client.endpoints.tokens
          .getBearerToken(login)
          // Make sure the response is valid.
          .then((response) => {
            if (response.status === 200) {
              return response.data.data;
            }
            return Promise.reject("Login failed.");
          })
          // Then update the tokens - this will save to state and storage.
          .then((data) => {
            data.username = this.loginForm.username;
            this.$store.dispatch("tokens/storeTokens", data);
          })
          // Bounce to dashboard, without providing parameters. This will trigger auto operator.
          .then(() => {
            this.$router.push({
              name: "/dashboard",
            });
          })
          // If anything goes wrong, just show a login failed and clear the tokens.
          .catch((error) => {
            window.log.error(error);
            this.loading = false;

            const toast = useToast();
            toast.error("Login failed.");

            store.dispatch("tokens/logout");
          });
      },
    },
  };
</script>

<style lang="scss">
  .login-container {
    max-width: $login_form_max_width;
    min-width: $login_form_min_width;
    width: $login_form_width;
    padding: 1rem 2rem 3rem;
    border-radius: $card_radius;
    box-shadow: $login_form_box_shadow;
  }
  h2.logo {
    margin: 2rem 0;
    img {
      min-width: $login_logo_img_min_width;
      max-width: $login_logo_img_max_width;
      width: $login_logo_img_width;
      margin: 0 auto;
      display: block;
      height: auto;
    }
  }
  .password-field {
    position: relative;
    input[name="password"] {
      width: 100%;
      padding-right: 50px;
    }
    button.switch-visibility {
      position: absolute;
      right: 0;
      color: #aaa;
      z-index: 4;
      width: 40px;
      height: 38px;
    }
  }
</style>
